@charset "UTF-8";
/* =================================================================
    reset
   ================================================================= */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, small, strong, b, i, dl, dt, dd, ol, ul, li, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, figcaption, figure, footer, header, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box; }

main {
  display: block; }

ul {
  list-style: none; }

img {
  vertical-align: top; }

html {
  font-size: 62.5%; }

body {
  font: 1.0rem/1.6 "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  color: #2b2b2b;
  background-color: #f6f6f6;
  -webkit-text-size-adjust: 100%;
  /* pc */
  /* tb */
  /* sp */ }
  @media (min-width: 1025px) {
    body {
      width: auto;
      min-width: 1280px;
      margin: 0 auto 0; } }
  @media (max-width: 1024px) {
    body {
      width: 100%;
      margin: 0 auto 0; } }
  @media (max-width: 768px) {
    body {
      width: 100%;
      margin: 0 auto 0; } }

a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  transition: all 0.1 ease; }
  a:hover {
    opacity: 0.7; }

p, h1, h2, h3, h4, h5, h6, ul, li, dl, dt, dd, label {
  background-image: url(../images/common/transparent.gif); }

/*===================================================================
 *
 * Frame Style Sheet
 *
/
===================================================================*/
/*===================================================================
	header
===================================================================*/
header {
  position: relative;
  height: 0;
  padding-top: 14%;
  z-index: 2;
  background: #fff;
  /* pc */ }
  header:after {
    content: "";
    display: block;
    clear: both; }
  @media (min-width: 768px) {
    header {
      min-width: 1280px;
      max-width: 1500px;
      margin: 0 auto;
      padding: 20px 0;
      height: auto; } }

#header_logo {
  width: 23.59375%;
  height: auto;
  position: absolute;
  left: 2%;
  top: 8px;
  z-index: 3;
  /* pc */ }
  @media (min-width: 768px) {
    #header_logo {
      width: 120px;
      left: 50%;
      margin-left: -610px; } }
  #header_logo a {
    display: block; }
    #header_logo a:hover {
      opacity: 1.0; }
  #header_logo img {
    width: 100%; }

nav {
  /* pc */ }
  @media (min-width: 768px) {
    nav {
      width: 1280px;
      margin: 0 auto;
      padding-top: 0px;
      background: none; } }
  nav #header_nav {
    display: none;
    margin: 12% 0 15%; }
    nav #header_nav a:hover {
      opacity: 1.0; }
    nav #header_nav li img {
      width: 37.65625%;
      height: auto; }
  nav #header_nav_pc {
    display: none;
    /* pc */ }
    @media (min-width: 768px) {
      nav #header_nav_pc {
        display: inline-block;
        margin: 0 100px 0 266px;
        vertical-align: middle; }
        nav #header_nav_pc:after {
          content: "";
          display: block;
          clear: both; }
        nav #header_nav_pc li {
          width: 145px;
          height: 40px;
          line-height: 40px;
          border-right: 1px solid #97a9b8;
          transform: skewX(-30deg); }
          nav #header_nav_pc li:first-child {
            border-left: 1px solid #97a9b8; }
          nav #header_nav_pc li img {
            width: auto;
            transform: skewX(30deg);
            vertical-align: middle; } }
    nav #header_nav_pc a:hover {
      opacity: 1.0; }
  nav li {
    margin: 0 0 10%;
    padding: 0;
    text-align: center;
    /* pc */ }
    @media (min-width: 768px) {
      nav li {
        float: left;
        margin: 0; } }
    nav li a:hover {
      box-sizing: border-box; }
    nav li a.current {
      box-sizing: border-box; }
  nav #header_sns {
    display: none;
    text-align: center; }
    nav #header_sns li {
      display: inline-block;
      width: 12.1875%;
      height: auto;
      margin: 0 2.8% 0; }
      nav #header_sns li a {
        display: block; }
      nav #header_sns li img {
        width: 100%; }
  nav #header_sns_pc {
    display: none;
    /* pc */ }
    @media (min-width: 768px) {
      nav #header_sns_pc {
        display: inline-block;
        vertical-align: middle; }
        nav #header_sns_pc li {
          margin-right: 6px; } }
  nav #nav_button {
    position: absolute;
    top: 14px;
    right: 4%;
    display: block;
    width: 30px;
    /* pc */ }
    @media (min-width: 768px) {
      nav #nav_button {
        display: none; } }
    nav #nav_button a {
      display: block; }
    nav #nav_button div {
      width: 30px; }
    nav #nav_button span {
      display: block;
      margin-bottom: 8px;
      width: 30px;
      height: 3px;
      background: #002d52; }
    nav #nav_button.nav_button_close {
      top: 16px; }
      nav #nav_button.nav_button_close span {
        width: 34px;
        background: #fff;
        transition: all 300ms ease; }
        nav #nav_button.nav_button_close span:nth-child(1) {
          transform: translate(0, 11px) rotate(45deg); }
        nav #nav_button.nav_button_close span:nth-child(2) {
          opacity: 0; }
        nav #nav_button.nav_button_close span:nth-child(3) {
          transform: translate(0, -11px) rotate(-45deg); }

/*===================================================================
	contentArea
===================================================================*/
main {
  position: relative;
  width: 100%;
  font-size: 0.7rem;
  z-index: 1;
  /* pc */ }
  @media (min-width: 768px) {
    main {
      min-width: 1280px;
      max-width: 1500px;
      margin: 0 auto; } }

.breadcramb {
  display: none;
  width: 87.5%;
  margin: 0 auto 4rem;
  color: #002d52;
  font-size: 1.3rem;
  /* pc */ }
  @media (min-width: 768px) {
    .breadcramb {
      display: block;
      width: 1174px;
      margin: 0 auto 68px; } }
  .breadcramb li {
    display: inline-block;
    margin-right: 1rem; }
    .breadcramb li:after {
      content: '>';
      margin-left: 1rem; }
    .breadcramb li:last-child:after {
      content: ''; }

/*===================================================================
	footer
===================================================================*/
footer {
  position: relative;
  padding: 25px 0 10px;
  text-align: center;
  color: #fff;
  background: #002d52;
  /* pc */ }
  @media (min-width: 768px) {
    footer {
      min-width: 1280px;
      max-width: 1500px;
      margin: 0 auto;
      padding: 25px 0 15px;
      text-align: left; } }
  footer #pageTop {
    position: absolute;
    right: 3.5%;
    bottom: 33px;
    width: 14.6875%;
    height: auto;
    /* pc */ }
    @media (min-width: 768px) {
      footer #pageTop {
        right: 30px;
        top: 28px;
        bottom: auto;
        width: 53px; } }
    footer #pageTop a {
      display: block; }
    footer #pageTop img {
      width: 100%; }
  footer .footer_sns {
    margin-bottom: 25px;
    /* pc */ }
    @media (min-width: 768px) {
      footer .footer_sns {
        width: 1280px;
        margin: 40px auto 20px; } }
    footer .footer_sns li {
      display: inline-block;
      width: 12.1875%;
      height: auto;
      margin: 0 2.8% 0;
      /* pc */ }
      @media (min-width: 768px) {
        footer .footer_sns li {
          width: 36px;
          margin: 0 7px 0 0; } }
      footer .footer_sns li a {
        display: block; }
      footer .footer_sns li img {
        width: 100%; }
  footer .footer_nav {
    margin-bottom: 25px;
    font-size: 1.0rem;
    /* pc */ }
    @media (min-width: 768px) {
      footer .footer_nav {
        width: 1280px;
        margin: 0 auto;
        font-size: 1.2rem; } }
    footer .footer_nav li {
      display: inline-block;
      margin-right: 3%;
      /* pc */ }
      @media (min-width: 768px) {
        footer .footer_nav li {
          margin-right: 30px; } }
      footer .footer_nav li:last-child {
        margin-right: 0; }
    footer .footer_nav a {
      color: #fff; }
  footer .footer_logo {
    display: inline-block;
    margin-bottom: 8px;
    width: 17.96875%;
    height: auto;
    /* pc */ }
    @media (min-width: 768px) {
      footer .footer_logo {
        position: absolute;
        width: 100px;
        top: 25px;
        left: 50%;
        margin-left: -50px; } }
    footer .footer_logo img {
      width: 100%; }
  footer .footer_copy {
    font-size: 0.8rem;
    /* pc */ }
    @media (min-width: 768px) {
      footer .footer_copy {
        position: absolute;
        right: 28px;
        bottom: 15px;
        text-align: right;
        font-size: 12px; } }
