@charset "UTF-8";
@import "config";

/* =================================================================
    reset
   ================================================================= */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, small, strong, b, i, dl, dt, dd, ol, ul, li, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, figcaption, figure, footer, header, nav, section {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    box-sizing:border-box;
}
main{
    display:block;
}
ul {
    list-style:none;
}
img {
    vertical-align:top;
}

// =================================================================
// common
// =================================================================
html{
	font-size: 62.5%;
}
body {
  font:1.0rem/1.6 "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
	color: $font-color;
	background-color: $bg-color;
	-webkit-text-size-adjust: 100%;

    /* pc */
    @media (min-width: 1025px) {
        width: auto;
        min-width: 1280px;
        margin: 0 auto 0;
    }
    /* tb */
    @media (max-width: 1024px) {
        width: 100%;
        margin: 0 auto 0;
    }
    /* sp */
    @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto 0;
    }
}


a{
	-webkit-tap-highlight-color:rgba(0,0,0,0); //リンクタップ時のハイライトカラーをなしにする
    text-decoration: none;
    transition: all 0.1 ease;
    &:hover{
        opacity: 0.7;
    }
}

// android 自動調整幅 bug対応
p,h1,h2,h3,h4,h5,h6,ul,li,dl,dt,dd,label {
	background-image: url(../images/common/transparent.gif);
}

/*===================================================================
 *
 * Frame Style Sheet
 *
/
===================================================================*/
/*===================================================================
	header
===================================================================*/
header {
    @include clearfix;
    position: relative;
    height: 0;
    padding-top: 14%;
    z-index: 2;
    background: #fff;

    /* pc */
    @media (min-width: 768px) {
        min-width: 1280px;
        max-width: 1500px;
        margin: 0 auto;
        padding: 20px 0;
        height: auto;
    }
}
#header_logo {
    @include imgwidth(151, 640);
    position: absolute;
    left: 2%;
    top: 8px;
    z-index: 3;

    /* pc */
    @media (min-width: 768px) {
        width: 120px;
        left: 50%;
        margin-left: -610px;
    }
    a{
        display: block;
        &:hover{
            opacity: 1.0;
        }
    }
    img{
        width: 100%;
    }
}
nav {
    /* pc */
    @media (min-width: 768px) {
        width: 1280px;
        margin: 0 auto;
        padding-top: 0px;
        background: none;
    }
    #header_nav  {
        display: none;
        margin: 12% 0 15%;
    a{
        &:hover{
        opacity: 1.0;
        }
    }
        li{
            img{
            @include imgwidth(241, 640);
            }
        }
    }
    #header_nav_pc{
      display: none;

       /* pc */
        @media (min-width: 768px) {
            display: inline-block;
            @include clearfix;
            margin: 0 100px 0 266px;
            vertical-align: middle;
            li{
                width: 145px;
                height: 40px;
                line-height: 40px;
                border-right: 1px solid #97a9b8;
                transform: skewX(-30deg);
                &:first-child{
                  border-left: 1px solid #97a9b8;
                }
                img{
                  width: auto;
                  transform: skewX(30deg);
                  vertical-align: middle;
                }
            }
        }
        a{
            &:hover{
            opacity: 1.0;
            }
        }
    }
    li {
	    margin: 0 0 10%;
	    padding: 0;
        text-align: center;

        /* pc */
        @media (min-width: 768px) {
            float: left;
            margin: 0;
        }
        a {
            &:hover{
                box-sizing: border-box;
            }
            &.current{
                box-sizing: border-box;
            }
        }
    }
    #header_sns{
        display: none;
        text-align: center;
        li{
          display: inline-block;
          @include imgwidth(78, 640);
          margin: 0 2.8% 0;
          a{
              display: block;
          }
          img{
              width: 100%;
          }
        }
    }
    #header_sns_pc{
        display: none;

        /* pc */
        @media (min-width: 768px) {
            display: inline-block;
            vertical-align: middle;
            li{
              margin-right: 6px;
            }
        }
    }
    #nav_button{
        position: absolute;
        top: 14px;
        right: 4%;
        display: block;
        width: 30px;

        /* pc */
        @media (min-width: 768px) {
            display: none;
        }
        a{
            display: block;
        }
        div{
                width: 30px;
        }
        span{
            display: block;
            margin-bottom: 8px;
            width: 30px;
            height: 3px;
            background: #002d52;
        }
        &.nav_button_close{
            top: 16px;
            span{
                width: 34px;
                background: #fff;
                transition: all 300ms ease;
                &:nth-child(1){
                    transform: translate(0, 11px) rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    transform: translate(0, -11px) rotate(-45deg);
                }

            }
        }
    }

}


/*===================================================================
	contentArea
===================================================================*/
main{
    position: relative;
    width: 100%;
	font-size: 0.7rem;
    z-index: 1;

    /* pc */
    @media (min-width: 768px) {
        min-width: 1280px;
        max-width: 1500px;
        margin: 0 auto;
    }
}
.breadcramb{
    display :none;
    width: 87.5%;
    margin: 0 auto 4rem;
    color: #002d52;
    font-size: 1.3rem;

    /* pc */
    @media (min-width: 768px) {
        display :block;
        width: 1174px;
        margin: 0 auto 68px;
    }
    li{
        display: inline-block;
        margin-right: 1rem;
        &:after{
            content: '>';
            margin-left: 1rem;
        }
        &:last-child{
            &:after{
                content: '';
            }
        }
    }
}

/*===================================================================
	footer
===================================================================*/
footer {
    position: relative;
    padding: 25px 0 10px;
    text-align: center;
    color: #fff;
    background: #002d52;

    /* pc */
    @media (min-width: 768px) {
        min-width: 1280px;
        max-width: 1500px;
        margin: 0 auto;
        padding: 25px 0 15px;
        text-align: left;
    }
    #pageTop{
        position: absolute;
        right: 3.5%;
        bottom: 33px;
        @include imgwidth(94, 640);

        /* pc */
        @media (min-width: 768px) {
          right: 30px;
          top: 28px;
          bottom: auto;
          width: 53px;
        }
        a{
            display: block;
        }
        img{
            width: 100%;
        }
    }
    .footer_sns{
        margin-bottom: 25px;

        /* pc */
        @media (min-width: 768px) {
            width: 1280px;
            margin: 40px auto 20px;
        }
        li{
            display: inline-block;
            @include imgwidth(78, 640);
            margin: 0 2.8% 0;

            /* pc */
            @media (min-width: 768px) {
              width: 36px;
              margin: 0 7px 0 0;
            }
            a{
                display: block;
            }
            img{
                width: 100%;
            }
        }
    }
    .footer_nav{
        margin-bottom: 25px;
        font-size: 1.0rem;

        /* pc */
        @media (min-width: 768px) {
            width: 1280px;
            margin: 0 auto;
            font-size: 1.2rem;
        }
        li{
            display: inline-block;
            margin-right: 3%;

            /* pc */
            @media (min-width: 768px) {
                margin-right: 30px;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        a{
            color: #fff;
        }
    }
    .footer_logo{
        display: inline-block;
        margin-bottom: 8px;
        @include imgwidth(115, 640);

        /* pc */
        @media (min-width: 768px) {
            position: absolute;
            width: 100px;
            top: 25px;
            left: 50%;
            margin-left: -50px;
        }
        img{
            width: 100%;
        }
    }
    .footer_copy{
        font-size: 0.8rem;

        /* pc */
        @media (min-width: 768px) {
            position: absolute;
            right: 28px;
            bottom: 15px;
            text-align: right;
            font-size: 12px;
        }
    }
}
