// =================================================================
// Variables
// =================================================================
//size
$sp-width: 480px;
$tb-width: 768px;
$pc-width: 1024px;
$max-width: 1280px;

$header-height: 58px;

//color
$top-font-color: #f2f2f2;
$top-bg-color: #0c0914;
$font-color: #2b2b2b;
$bg-color: #f6f6f6;
$theme-color: #0c0914;


// =================================================================
// mixin
// =================================================================
//コンテンツの幅を指定し中央寄せにする
@mixin wrap($width: 90%){
	width: $width;
	margin: 0 auto;
}

//横幅の比率で画像を拡縮
@mixin imgwidth($img-width, $parent-width){
	width: $img-width/$parent-width * 100%;
	height: auto;
}

//opacity IE対応版
@mixin opacity($opacity) {
     opacity: $opacity;
     $opacityIE: $opacity * 100;
     filter: alpha(opacity=$opacityIE);
}

// メディアクエリの設定
// arguments: tb,px,max
@mixin mq($width) {
	@if $width == sp {
		@media (min-width: $sp-width) {
	    	@content;
	    }
	} @else if $width == tb{
		@media (min-width: $tb-width) {
	    	@content;
	    }
	} @else if $width == pc{
		@media (min-width: $pc-width) {
	    	@content;
	    }
	} @else if $width == max{
		@media (min-width: $max-width) {
	    	@content;
	    }
	}
}

//clearfix
@mixin clearfix() {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
